.color-picker {
	width: 30px;
	height: 30px;

	.swatch {
		width: 100%;
		height: 100%;
		padding: 5px;
		background-color: #fff;
		cursor: pointer;

		div {
			width: 100%;
			height: 100%;
		}
	}
}

.color-picker-popover {
	position: absolute;
	top: $height-header;
	left: 0;
	z-index: 1000;
}

.color-picker-cover {
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
}