.pac-container {
    display: block !important;

    &.pac-logo::after {
        padding: 0;
    }

    & .pac-item {
        cursor: pointer;
    }
}
