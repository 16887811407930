.leaflet-tooltip {
	background: none;
	border: none;
	box-shadow: none;
	padding: 0;

	&:before {
		content: none !important;
	}

	div {
		border-radius: 3px;
		color: #222;
		white-space: nowrap;
		box-shadow: 0 1px 3px rgba(0,0,0,0.4);

		padding: 1px;
		background-color: rgba(255, 255, 255, 0.4);
		border: none;
	}

	&.leaflet-tooltip-left {
		margin-left: -18px;

		& div {
			box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.4);

			&:before {
				border-left-color: rgba(255, 255, 255, 0.4);
			}
		}
	}

	&.leaflet-tooltip-right {
		margin-left: 18px;

		& div {
			box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);

			&:before {
				border-right-color: rgba(255, 255, 255, 0.4);
			}
		}
	}

	&.leaflet-tooltip-top {
		margin-top: -18px;

		& div {
			box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.4);

			&:before {
				border-top-color: rgba(255, 255, 255, 0.4);
			}
		}
	}

	&.leaflet-tooltip-bottom {
		margin-top: 18px;

		& div {
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);

			&:before {
				border-bottom-color: rgba(255, 255, 255, 0.4);
			}
		}
	}
}

/* Adaptation of Leaflet tooltip */
.leaflet-tooltip-top div:before,
.leaflet-tooltip-bottom div:before,
.leaflet-tooltip-left div:before,
.leaflet-tooltip-right div:before {
	position: absolute;
	pointer-events: none;
	border: 6px solid transparent;
	background: transparent;
	content: "";
}

/* Directions */
.leaflet-tooltip-bottom div:before,
.leaflet-tooltip-top div:before {
	left: 50%;
	margin-left: -6px;
}
.leaflet-tooltip-top div:before {
	bottom: 0;
	margin-bottom: -12px;
	border-top-color: #fff;
}
.leaflet-tooltip-bottom div:before {
	top: 0;
	margin-top: -12px;
	margin-left: -6px;
	border-bottom-color: #fff;
}
.leaflet-tooltip-left div:before,
.leaflet-tooltip-right div:before {
	top: 50%;
	margin-top: -6px;
}
.leaflet-tooltip-left div:before {
	right: 0;
	margin-right: -12px;
	border-left-color: #fff;
}
.leaflet-tooltip-right div:before {
	left: 0;
	margin-left: -12px;
	border-right-color: #fff;
}