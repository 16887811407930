body {
	font-family: "Gotham";
}

body, .btn, .form-control {
	font-size: 13px;
}

h3 {
	font-size: 22px;
	line-height: 35px;
}

h4 {
	font-size: 14px;
	line-height: 22px;
}

h5 {
	font-size: 10px;
	font-weight: 200;
	color: $color-primary;
	text-transform: uppercase;
}

.cursor-pointer {
	cursor: pointer;
}