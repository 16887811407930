@mixin font-face($style-name, $file, $weight: 500) {
	$filepath: '../../fonts/' + $file;
    @font-face {
		font-family: $style-name;
		font-weight: $weight;
        src: url($filepath + ".otf") format("opentype");
    }
}


@include font-face('Gotham', 'Gotham-Medium');
@include font-face('Gotham', 'Gotham-Bold', 700);
@include font-face('Gotham', 'Gotham-Book', 200);
