$color-primary: #e4032b;
$light-grey: #d9d9d9;
$dark-grey: #2A2D35;
$green: #6abe45;

$height-filters: 170px;
$height-filters-sm: 60vh;
$height-header: 60px;
$width-panel: 360px;

$theme-colors: (
	"primary": $color-primary,
	"light": $light-grey,
	"dark": #4a4b50
);

$input-btn-focus-width:       .2rem !default;
$input-btn-focus-color:       rgba(255, 255, 255, .25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;