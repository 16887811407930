.container-filters {
	position: relative;

	$btn-toggle-size: 40px;
	.btn-toggle {
		z-index: 1000;
		position: absolute;
		border-radius: 50%;
		width: $btn-toggle-size;
		height: $btn-toggle-size;
		top: - #{$btn-toggle-size + 5px};
		left: 5px;
		transition: all 0.5s ease;

		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			margin: 0;
			font-size: 18px
		}
	}

	&.active {
		.btn-toggle {
			top: - #{$btn-toggle-size/2};
		}

		.filters {
			height: $height-filters;

			@include media-breakpoint-down(sm) {
				height: $height-filters-sm;
			}
		}
	}

	.filters {
		display: flex;
		color: #FFF;
		background-color: $dark-grey;
		transition: all 0.5s ease;
		height: 0;
		overflow: hidden;

		& > div {
			padding: 15px;
			height: 100%;
		}

		.sectors {
			flex: 1;
			max-height: 120px;
			column-count: 5;
		}

		.types {
			height: 100%;
		}

		.sectors+div {
			border-left: 1px solid $dark-grey;

			.reset-filters {
				border-top: 1px solid $dark-grey;
			}
		}

		.btn-link {
			text-decoration: none;
			border-radius: 0;
			border-bottom: 1px solid #5f6064;
			line-height: 17px;

			&:hover {
				text-decoration: none;
				border-bottom-color: #FFF;
			}
		}

		@media (max-width: 1450px) {
			.checkbox-button input + label {
				width: 55px;
				height: 55px;
			}
		}

		@media (max-width: 1390px) {
			.types {
				flex-direction: column;
			}

			.checkbox-button input + label {
				width: 40px;
				height: 40px;

				&::after {
					display: none;
				}
			}

			.sectors {
				column-count: 4;
			}

			.title {
				display: none;
			}

			.flex-column {
				padding: 10px;
			}
		}

		@media (max-width: 995px) {
			.sectors {
				max-height: none;
			}
		}

		@include media-breakpoint-down(sm) {
			display: flex;
			flex-direction: column;

			& > div {
				height: auto;
				flex-grow: 0;
			}

			.sectors {
				column-count: 2;
				max-height: none;

				& + div, & + div .reset-filters {
					border: none;
				}
			}

			.types {
				flex-direction: row;
				height: auto;

				.checkbox-button {
					margin-left: 5px;
					margin-right: 5px;
				}
			}

			.flex-column {
				padding: 5px;

				padding-bottom: 0;
			}
		}
	}

	@media (max-width: 580px) {
		&.active {
			.filters {
				height: auto;
				min-height: $height-filters-sm;
			}
		}

		.sectors + div {
			margin: auto; 
		}
	}
}