nav.bg-dark {
	height: $height-header;
	background-color: $dark-grey !important;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0;

	.navbar-brand {
		img {
			margin-bottom: 8px;
			height: 35px;
		}
	}

	ul.nav.bg-dark {
		flex-wrap: nowrap;
		align-items: center;

		li {
			border-right: 1px solid $dark-grey;
		}
	}

	.search-results {
		min-width: 150px;
	}

	.navbar-collapse {
		z-index: 1001;
		background-color: $dark-grey;
	}

	.avatar {
		text-transform: uppercase;
		border: 1px solid #FFF;
		border-radius: 50%;
		margin-left: 1rem;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		span {
			background-color: #9fa9d8;
			padding: 1px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			margin: 2px;
		}
	}

	.btn:focus, .btn.focus {
		box-shadow: none;
	}

	.dropdown {
		.dropdown-toggle > span:first-child {
			font-size: 14px;
		}

		.dropdown-item {
			font-size: 12px;
		}
	}

	@media (max-width: 1280px) {
		.search-results {
			min-width: auto;
		}

		.nav-link {
			padding: 5px;
		}
	}

	@media (max-width: 1130px) {
		.nav-item .nav-link {
			padding: 0.375rem 0.75rem;

			svg + span {
				display: none;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.navbar-brand {
			img {
				max-width: 150px;
			}
		}

		.input-group {
			flex-wrap: nowrap;

			.btn {
				padding: 5px 8px;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.input-group {
			width: auto;
			margin: 15px;

			.btn {
				padding: 6px;
			}
		}

		.search-results {
			padding-bottom: 15px;
		}

		ul.nav.bg-dark {
			display: inline-flex;
		}

		.nav-item .nav-link {
			svg + span {
				display: block;
			}
		}

		.dropdown-toggle {
			margin-left: 3px;
			margin-top: 5px;
		}
	}
}