.login {
	background-image: url('/img/background-login.png');
	background-size: cover;
	background-position: center right;
	background-repeat: no-repeat;
	overflow: hidden;
	position: relative;
	height: 100vh;

	&::before {
		content: '';
		width: 60%;
		height: 130%;
		background-color: #FFF;
		position: absolute;
		right: -10%;
		top: -15%;
		opacity: 0.95;
		transform: rotate(-10deg);

		@include media-breakpoint-down(md) {
			width: 70%;
		}

		@include media-breakpoint-down(sm) {
			transform: rotate(0deg);
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
	}

	h1 {
		font-size: 25px;
	}

	.img-fluid {
		max-width: 65%;
	}

	.copyright {
		position: absolute;
		bottom: 0;

		@include media-breakpoint-down(sm) {
			position: relative;
		}
	}

	a {
		text-decoration: underline;
		margin-top: 15px;
		font-size: 12px;
	}

	.input-group-append {
		.btn {
			border-color: #ced4da;
			padding: 5px 12px;
		}
	}

	@include media-breakpoint-down(sm) {
		overflow: auto;
	}
}