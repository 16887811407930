.btn {
	display: inline-flex;
	align-items: center;

	svg {
		font-size: 16px;
		vertical-align: inherit;
		margin-right: 5px;
	}

	&.btn-white {
		background-color: #FFF;
		color: #000;

		&:hover {
			cursor: pointer;
			background-color: $light-grey;
		}
	}

	&.btn-link {
		text-decoration: underline;
	}
}

.btn-outline-dark {
	background-color: #FFF;
	
	&, &:hover {
		border: 1px solid #FFF;
	}
}

.pdf-button {
	&:hover {
		text-decoration: none;
	}

	.spinner-border {
		margin-right: 5px;
	}
}