.mobile-warning {
	background-color: #2a2e36;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;

	p {
		color: #FFF;
		text-align: center;
		font-size: 20px;
	}
}