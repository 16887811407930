.snapshot {
    .leaflet-tooltip {
        div {
            transform: scale(0.7);
        }

        &.leaflet-tooltip-left div {
            transform-origin: right center;
        }

        &.leaflet-tooltip-right:not(.leaflet-tooltip-vertical) div {
            transform-origin: left center;
        }

        // TODO: fix margin top or disable scale for vertical tooltips
        &.leaflet-tooltip-vertical {
            margin-top: -8px;

            & div {
                transform: scale(0.7) rotate(90deg) !important;  
            }
        }
    }

    // .circle-marker {
    //     opacity: 0;
    // }
}