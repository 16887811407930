.panel {
	width: $width-panel;
	position: relative;
	background-color: #FFF;
	overflow-x: hidden;
	vertical-align: top;
	z-index: 400;
	-webkit-box-shadow: -10px 0px 14px -10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: -10px 0px 14px -10px rgba(0, 0, 0, 0.2);
	box-shadow: -10px 0px 14px -10px rgba(0, 0, 0, 0.2);
	min-height: calc(100vh - #{$height-header});
	max-height: calc(100vh - #{$height-header});

	p {
		font-size: 12px;
		font-weight: 200;
	}

	.btn-link {
		font-weight: 200;
	}

	.buttons {
		flex-wrap: wrap;
		gap: 0.25rem;
	}

	.floor {
		img {
			max-width: 80%;
			height: 100%;
		}
	}
}

.active-filters+.panel {
	max-height: calc(100vh - #{$height-header} - #{$height-filters});
	min-height: calc(100vh - #{$height-header} - #{$height-filters});

	@include media-breakpoint-down(sm) {
		max-height: calc(100vh - #{$height-header} - #{$height-filters-sm});
		min-height: calc(100vh - #{$height-header} - #{$height-filters-sm});
	}
}

.panel-sliding {
	right: -#{$width-panel};
	
	transition: right 0.5s;
	z-index: 10000;
	width: 0;
	margin-left: -35px;

	& .panel-toggle {
		position: fixed;
		right: 0;
		bottom: 10px;
		width: 30px;
		height: 35px;
		background-color: #FFF;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition: right 0.5s;
		box-shadow: -3px 0px 4px rgba(0, 0, 0, 0.1);
		border: 1px solid $light-grey;
		border-right: none;

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	&.active {
		right: 0;
		width: $width-panel;
		min-width: $width-panel;
		margin-left: 0;

		& .panel-toggle {
			right: $width-panel;
		}
	}
}

.active-filters+.panel-sliding {
	& .panel-toggle {
		bottom: calc(#{$height-filters} + 10px);
	}
}