.map-container {
	width: 100%;
	position: relative;
	display: inline-block;
	height: calc(100vh - #{$height-header});
	transition: height 0.5s ease;

	.leaflet-google-mutant {
		height: 100% !important;

		.gm-style {
			iframe + div {
				left: 50px !important;
			}
		}
	}

	.leaflet-container {
		height: 100%;
	}

	.leaflet-control-geosearch {
		form {
			height: 100%;
		}
	}

	&.active-panel {
		width: calc(100% - #{$width-panel});
		
		& ~ .panel {
			display: inline-block;
			overflow-y: auto;
		}
	}

	&.active-filters {
		height: calc(100vh - #{$height-header} - #{$height-filters});

		@include media-breakpoint-down(sm) {
			height: calc(100vh - #{$height-header} - #{$height-filters-sm});
		}
	}

	.leaflet-control {
		.custom-control {
			padding: 0;
			max-width: 34px;
			max-height: 34px;

			button {
				background-color: #FFF;
				min-width: 34px;
				min-height: 34px;
				border: 0;
				border-radius: 4px;
				border: 2px solid rgba(0, 0, 0, 0.2);
				background-clip: padding-box;

				&:focus {
					outline: none;
				}
			}
		}

		&.leaflet-pegman {
			width: 34px;
			height: 34px;
		}

		&.leaflet-bar {
			.custom-control {
				button {
					border: none;
					border-radius: 0;
					min-width: 30px;
					min-height: 30px;

					&:not(:last-child) {
						border-bottom: 1px solid #CCC;
					}
				}
			}
		}

		&.color-control {
			z-index: 999;

			.custom-control {
				max-height: none;
			}
		}
	}
}

.marker-cluster-custom {
	background: #FFF;
	border: 1px solid $color-primary;
	border-radius: 50%;
	color: $color-primary;
	height: 30px;
	width: 30px;
	line-height: 29px;
	text-align: center;
}

.leaflet-control-geosearch {
	.results {
		position: absolute;
		bottom: 30px;
		right: 0;
	}
}
