.custom-switch {
	$height-switch: 12px;
	$size-ball: 18px;
	$color-before: #abacae;

	.custom-control-label {
		&::before {
			height: $height-switch;
			background-color: $color-before;
			border: none;
		}

		&::after {
			width: $size-ball;
			height: $size-ball;
			top: 1px;
			left: calc(-2.25rem - 1px);
			background-color: #6c6d6d;
		}
	}

	.custom-control-input:checked ~ .custom-control-label {
		&::before {
			background-color: $color-before;
		}

		&::after {
			background-color: $green;
		}
	}

	.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
		background-color: $color-before;
	}
}